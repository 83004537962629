<template>
  <Navbar :routes="routes" />
  <div class="min-height block relative overflow-x-hidden max-w-scren">
    <router-view v-slot="{ Component }">
      <transition name="fade">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
  <Footer :routes="routes" />
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import { routes } from "@/vars";

export default {
  name: "App",
  components: {
    Navbar,
    Footer,
  },
  setup() {
    return {
      routes: routes.filter((route) => !route.hidden),
    };
  },
};
</script>
<style>
body {
  overflow-x: hidden;
}
.min-height {
  min-height: calc(100vh - 5rem);
}

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.5s;
}

.fade-enter-active {
  transition-delay: 0.5s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
