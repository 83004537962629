<template>
  <Menu as="div" class="relative inline-block text-left z-30">
    <div>
      <MenuButton class="font-light text-black uppercase">
        {{ route.name }}
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        class="absolute -left-2/3 mt-2 px-8 py-4 w-72 origin-top-center bg-white rounded-md shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer"
      >
        <MenuItem
          class="inline-block w-full uppercase"
          v-for="(route, index) in route.childRoutes"
          :key="index"
        >
          <router-link
            class="px-3 py-2 font-light text-black uppercase hover:text-blue-400"
            :to="route.path"
          >
            {{ route.name }}
          </router-link>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";

export default {
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
  },
  props: ["route"],
  setup() {
    return { active: false };
  },
};
</script>
<style scoped>
.router-link-active {
  @apply text-blue-400;
}
</style>
