<template>
  <div class="h-96 bg-gray-800 items-center flex">
    <!-- <div class="mx-auto px-2 sm:px-6 lg:px-8"> -->
    <div class="relative mx-auto container flex justify-around">
      <div class="lg:flex justify-evenly items-center w-full">
        <div class="ml-0 mb-4">
          <img
            class="w-48 h-auto mx-auto"
            src="@/assets/logo_white.png"
            alt="Grupo LON"
          />
          <div class="flex mt-4 w-full justify-center text-center">
            <img
              src="@/assets/iso_9001.png"
              alt="instagram"
              class="m-2 h-14 w-auto"
            />
            <img
              src="@/assets/iso_14001.png"
              alt="facebook"
              class="m-2 h-14 w-auto"
            />
            <img
              src="@/assets/iso_45001.png"
              alt="linked in"
              class="m-2 h-14 w-auto"
            />
          </div>
        </div>
        <div class="hidden md:block my-12 md:my-2 space-y-1 capitalize mx-auto">
          <router-link
            v-for="(item, index) in filteredRoutes"
            :key="index"
            :to="item.path"
            class="block text-white px-3 py-2 rounded-md text-base font-medium"
            >{{ item.name }}</router-link
          >
        </div>
        <div class="text-center">
          <h4 class="md:w-auto text-center text-white mb-4 mx-auto">
            Síguenos en las redes sociales
          </h4>
          <div class="flex justify-center lg:justify-around space-x-4 w-full">
            <a href="https://www.instagram.com/lavanderialanucia">
              <img
                src="@/assets/logo_instagram.png"
                alt="instagram"
                class="h-10 lg:h-12 w-auto"
              />
            </a>
            <a href="https://www.facebook.com/GRUPOLON">
              <img
                src="@/assets/logo_facebook.png"
                alt="facebook"
                class="h-10 lg:h-12 w-auto"
              />
            </a>
            <a href="https://www.linkedin.com/company/74006411">
              <img
                src="@/assets/logo_linkedin.png"
                alt="linked in"
                class="h-10 lg:h-12 w-auto"
              />
            </a>
          </div>
          <div class="mt-auto mb-0 pt-8">
          <a
            class="block text-white opacity-30 px-3 py-1 rounded-md text-base font-medium"
            :href="docs.cmss"
          >
          Política CMSS
          </a>
          <a
            class="block text-white px-3 opacity-30 rounded-md text-base font-medium"
            :href="docs.compromiso"
          >
          Compromiso de la Dirección
          </a>
          </div>
          <!-- <img class="mx-auto" src="@/assets/logo_white.png" alt="Grupo LON" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { docs } from '@/vars'

export default {
  name: "lonfooter",
  props: ["routes"],
  data() {
    return { docs }
  },
  computed: {
    filteredRoutes: function () {
      const routes = this.routes.filter((route) => route.name !== "Servicios");
      const childRoutes = this.routes.find(
        (route) => route.name == "Servicios"
      ).childRoutes;
      routes.splice(routes.length - 1, 0, ...childRoutes);
      return routes;
    },
  },
};
</script>

<style scoped>
.router-link-active {
  @apply text-blue-300;
}
</style>
