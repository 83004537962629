<template>
  <div class="flex flex-col items-center justify-center h-full w-full absolute">
    <img class="w-48 h-auto" src="@/assets/logo_dark.png" alt="Grupo LON" />
    <h2 class="text-2xl my-12 flex-shrink-0">Página no encontrada</h2>
  </div>
</template>

<script>
export default {
  name: "404",
};
</script>
