<template>
  <Disclosure
    as="nav"
    class="bg-white shadow-md overflow-visible z-50"
    v-slot="{ open }"
  >
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-20">
        <div class="absolute inset-y-0 right-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton
            class="
              inline-flex
              items-center
              justify-center
              text-gray-800
              hover:text-black
              focus:outline-none
              mr-3
            "
          >
            <span class="sr-only">Open main menu</span>
            <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div
          class="flex-1 flex items-center sm:items-stretch sm:justify-between"
        >
          <div class="mx-auto sm:ml-0 flex-shrink-0 flex items-center">
            <router-link to="/">
              <img
                class="block h-16 w-auto sm:ml-4"
                src="@/assets/logo_dark.png"
                alt="Grupo LON"
              />
            </router-link>
          </div>
          <div class="hidden ml-auto sm:block">
            <div class="flex space-x-4 uppercase h-full items-center">
              <router-link
                v-for="item in routes"
                :key="item.name"
                :to="item.childRoutes ? $route.path : item.path"
                class="px-3 py-2 font-light text-black"
                :class="item.childRoutes ? 'inactive' : ''"
                :aria-current="item.current ? 'page' : undefined"
              >
                <NavDropdown v-if="item.childRoutes" :route="item" />
                <span v-else>{{ item.name }}</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-out"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <DisclosurePanel class="sm:hidden">
        <div class="px-2 pt-2 pb-3 space-y-1 uppercase">
          <div
            class="block px-3 py-2 rounded-md text-base font-medium"
            v-for="(item, index) in mobileRoutes"
            :key="index"
          >
            <div @click="close" v-if="item.childRoutes">
              {{ item.name }}
            </div>
            <router-link
              v-else
              @click="close"
              :to="item.path"
              :aria-current="item.current ? 'page' : undefined"
              >{{ item.name }}
            </router-link>
          </div>
        </div>
      </DisclosurePanel>
    </transition>
  </Disclosure>
</template>

<script>
import NavDropdown from "@/components/NavDropdown";

import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { MenuIcon, XIcon } from "@heroicons/vue/outline";

export default {
  name: "Navbar",
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MenuIcon,
    XIcon,
    NavDropdown,
  },
  props: ["routes"],
  computed: {
    mobileRoutes() {
      const _routes = this.routes;
      const servicios = _routes.find((r) => r.childRoutes);
      _routes.splice(2, 1, ...servicios.childRoutes);
      return _routes;
    },
  },
  methods: {
    close() { this.open = false }
  },
  setup() {
    return { open: false };
  },
};
</script>

<style scoped>
@screen md {
  .mobile-link {
    display: none;
  }
}

.inactive.router-link-active {
  @apply text-black;
}

.router-link-active {
  @apply text-blue-500;
}
</style>
