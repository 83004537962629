import Missing from "@/components/404.vue";

export const baseUrl = process.env.BASE_URL;

const Grupo = () => import("@/components/Grupo.vue");
const Landing = () => import("@/components/landing/Landing.vue");
const Contacto = () => import("@/components/Contacto");
const Servicio = () => import("@/components/servicios/Servicio.vue");
const Newsletter = () => import("@/components/Newsletter.vue");

export const routes = [
  {
    path: "/",
    name: "Inicio",
    component: Landing,
    meta: {
      title: "Inicio - Grupo LON",
      metaTags: [
        {
          name: "description",
          content: "Grupo LON",
        },
        {
          property: "og:description",
          content: "Grupo LON",
        },
      ],
    },
  },
  {
    path: "/grupo",
    name: "Grupo LON",
    component: Grupo,
    meta: {
      title: "Grupo LON",
      metaTags: [
        {
          name: "description",
          content: "Grupo LON",
        },
        {
          property: "og:description",
          content: "Grupo LON",
        },
      ],
    },
  },
  {
    path: "/servicios/:slug",
    component: Servicio,
    childRoutes: [
      {
        name: "Lavandería Industrial",
        path: "/servicios/lavanderia-industrial",
      },
      {
        name: "Lavandería Select",
        path: "/servicios/lavanderia-select",
      },
      {
        name: "Renting Textil",
        path: "/servicios/renting-textil",
      },
      {
        name: "Vestuario Laboral",
        path: "/servicios/vestuario-laboral",
      },
    ],
    name: "Servicios",
    meta: {
      title: "Servicios - Grupo LON",
      metaTags: [
        {
          name: "description",
          content: "Servicios - Grupo LON",
        },
        {
          property: "og:description",
          content: "Servicios - Grupo LON",
        },
      ],
    },
  },
  {
    path: "/newsletter",
    name: "Newsletter",
    component: Newsletter,
    meta: {
      title: "Newsletter - Grupo LON",
      metaTags: [
        {
          name: "description",
          content: "Newsletter - Grupo LON",
        },
        {
          property: "og:description",
          content: "Newsletter - Grupo LON",
        },
      ],
    },
  },
  {
    path: "/contacto",
    name: "Contacto",
    component: Contacto,
    meta: {
      title: "Contacto - Grupo LON",
      metaTags: [
        {
          name: "description",
          content: "Contacto - Grupo LON",
        },
        {
          property: "og:description",
          content: "Contacto - Grupo LON",
        },
      ],
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: Missing,
    hidden: true,
  },
];

// TODO: Set actual texts for the dots
export const mvv = [
  {
    title: "Misión",
    dots: [
      "Crecer e innovar sin perder la esencia familiar es nuestra razón de ser.",
      "Ofrecer toda la gama de servicios de lavado industrial y retail textil atendiendo con eficacia y calidad las singularidades de cada cliente.",
      "Mantener la cercanía, la calidad incomparable y los pequeños detalles en el servicio para lograr una experiencia única y generar relaciones duraderas.",
      "Conservar el legado para que futuras generaciones continúen la tradición de excelencia y valor que nos identifica.",
    ],
  },
  {
    title: "Visión",
    dots: [
      "Reinventar la empresa familiar para crecer desde la sostenibilidad, la rentabilidad y la Responsabilidad Social Corporativa.",
      "Avanzar adaptándonos a las exigencias del mercado preservando la proximidad y las ventajas de la esencia familiar.",
      "Invertir e innovar para seguir siendo referentes en el mercado, atender con cercanía las necesidades de los clientes y mantener la competitividad en el precio.",
      "Contribuir a la protección del medio ambiente y la salud de las personas aplicando tecnologías ecológicas en el desempeño de la actividad.",
    ],
  },
  {
    title: "Valores",
    dots: [
      "El ejemplo de nuestra familia es el mejor transmisor de los principios que nos mueven y nos motivan cada día.",
    ],
    extraDots: [
      "Responsabilidad",
      "Pasión",
      "Integridad",
      "Cercanía",
      "Compromiso",
      "Colaboración",
      "Igualdad",
      "Diversidad",
      "Actitud de mejora",
      "Cuidado del planeta",
    ],
  },
];

export const contacto = {
  icon: `${baseUrl}img/ic_contacto_white.png`,
  mails: [
    "clientes@lavanderialanucia.es",
    "rrhh@lavanderialanucia.es",
    "provedores@lavanderialanucia.es",
  ],
  phone: {
    name: "673 378 385",
    number: "+34673378385",
  },
};

export const newsletter = {
  text: "En nuestro constante proceso de cambio y mejora, hemos creído importante que estés al tanto de nuestras últimas novedades e innovaciones a través de nuestra newsletter Habla. Esperamos que disfrutes leyenda, tanto como nosotros preparándola.",
  icon: `${baseUrl}img/ic_habla.png`,
  docs: [
    {
      title: "Nº1 Clientes",
      date: "Noviembre 2021",
      preview: `${baseUrl}img/newsletter/portada_lon_externa_1.png`,
      link: `${baseUrl}doc/newsletter_externa_1.pdf`,
    },
    {
      title: "Nº2 Interna",
      date: "Noviembre 2021",
      preview: `${baseUrl}img/newsletter/portada_lon_interna_2.png`,
      link: `${baseUrl}doc/newsletter_interna_2.pdf`,
      internal: true,
    },
    {
      title: "Nº1 Interna",
      date: "Marzo 2021",
      preview: `${baseUrl}img/newsletter/portada_lon_interna_1.jpg`,
      link: `${baseUrl}doc/newsletter_interna_1.pdf`,
      internal: true,
    },
  ]
}

export const grupo = {
  icon: `${baseUrl}img/ic_grupo_lon.png`,
  historia: {
    img: `${baseUrl}img/historia.jpg`,
    title: "Historia",
    text: `Hace 35 años, Cornelio Martínez y Lucía Sanchez dieron una oportunidad a su espíritu emprendedor abriendo una pequeña lavandería en el Mercado El Cruce de Benidorm. Cuatro lavadoras y dos secadoras en un local de 80 metros cuadrados fueron los pilares sobre los que se edificó un negocio pensado para el autoservicio de particulares.<br><br>
    Pronto descubrieron que la avanzada propuesta para la época se podía complementar dando servicio al sector de apartamentos y a la restauración. En seis años el negocio alcanzó tal crecimiento que tuvieron que recurrir a la externalización. La llama emprendedora cobró fuerza de nuevo y en 1992 trasladaron la lavandería a una nave de 200 metros cuadrados y reinvirtieron los beneficios en maquinaria más productiva y de mayor calidad para mejorar el servicio.`,
    full: `Hace 35 años, Cornelio Martínez y Lucía Sanchez dieron una oportunidad a su espíritu emprendedor abriendo una pequeña lavandería en el Mercado El Cruce de Benidorm. Cuatro lavadoras y dos secadoras en un local de 80 metros cuadrados fueron los pilares sobre los que se edificó un negocio pensado para el autoservicio de particulares. Pronto descubrieron que la avanzada propuesta para la época se podía complementar dando servicio al sector de apartamentos y a la restauración.\n
En seis años el negocio alcanzó tal crecimiento que tuvieron que recurrir a la externalización. La llama emprendedora cobró fuerza de nuevo y en 1992 trasladaron la lavandería a una nave de 200 metros cuadrados y reinvirtieron los beneficios en maquinaria más productiva y de mayor calidad para mejorar el servicio.\n
Con la entrada del nuevo siglo los fundadores de la Lavandería La Nucía apostaron de nuevo por dar un impulso al negocio adquiriendo una nave industrial en la localidad de Polop de La Marina que les permitiera atender con eficiencia y calidad a las grandes cadenas hoteleras de la Marina Baixa alicantina. Durante los años posteriores irían ampliando sus instalaciones hasta llegar a los 3.700 metros cuadrados actuales.\n
Un salto de vértigo que constituyó el pilar sobre el que la pequeña empresa familiar acabaría convirtiéndose en una de las lavanderías industriales más relevantes en España. Primero, liderando el sector en la provincia de Alicante y, después, extendiendo el servicio a las vecinas Valencia y Murcia. Del mismo modo, fueron ampliando la oferta de lavandería a nuevos sectores de actividad como las clínicas, al tiempo que apostaron por la profesionalización del equipo.\n
En 2007, llegó savia nueva a la empresa con la incorporación a la dirección de una nueva generación familiar. La formación continua del personal y el respeto por el medioambiente han marcado desde entonces la actitud de la compañía con respecto a la Responsabilidad Social Corporativa que toda empresa debe contemplar.  Y una década después, dieron un nuevo paso de gigante al añadir 4.000 metros cuadrados más a sus instalaciones, esta vez en la localidad de Xeraco.\n
El esfuerzo, constancia y la mejora continua que imprimieron los fundadores siguen siendo las señas de identidad de la empresa, que ahora ha pasado a denominarse Grupo LON y ha añadido nuevos servicios como la lavandería select, el renting textil o el vestuario laboral. Los cuatro empleados iniciales se han convertido en una plantilla de 320 con 21 perfiles diferentes según la función que desempeñan y la flota de camiones se desplaza cada día para dar servicio a las vecinas Valencia y Murcia.\n
Hoy, Cornelio y Lucía se felicitan porque su sueño, en el que pusieron tanto esfuerzo como pasión, se ha aupado al Top 6 del sector de lavandería industrial a nivel nacional. Y los proyectos de  innovación y de mejora de la calidad del servicio a precios competitivos continúan. No puede ser de otra forma, esta filosofía forma parte del ADN de la empresa.\n
Desde 1986, la apuesta de la empresa familiar por la calidad y el servicio al cliente al mejor precio posible se mantiene firme. Del mismo modo, el asesoramiento permanente para mejorar los circuitos y los procesos relacionados con el servicio de lavandería sigue siendo otra prioridad del Grupo LON. `,
  },
  isos: [
    {
      image: "/img/iso_9001.jpg",
      title: "Calidad",
      text:
        "La ISO 9001 certifica la calidad en todas las actividades de nuestra organización. Corrobora la satisfacción del cliente y la capacidad de proveer productos y servicios de excelencia.",
    },
    {
      image: "/img/iso_14001.jpg",
      title: "Gestión ambiental",
      text:
        "La ISO 14001 es un estándar internacionalmente aceptado que indica que observamos un sistema de gestión medioambiental efectivo y avala nuestras responsabilidades medioambientales.",
    },
    {
      image: "/img/iso_45001.jpg",
      title: "Seguridad y salud laboral",
      text:
        "La ISO 45001 es la medida internacional que garantiza nuestro compromiso para proteger a los trabajadores y visitantes de accidentes y enfermedades laborales.",
    },
  ],
  delegaciones: [
    {
      title: "Polop de la Marina",
      img: `${baseUrl}img/espacios/polop.jpg`,
      descr: `Un enclave estratégico en el corredor del Mediterráneo a un paso de Benidorm y con acceso a las principales vías de transporte, que permite la gestión más eficiente en la entrega y recogida de ropa. Las instalaciones de 3.700 metros cuadrados de superficie están dotadas con la maquinaria de más alto nivel para ofrecer tratamientos de la máxima calidad y la flexibilidad necesaria para adaptarnos a las necesidades específicas de cada cliente. Cada día procesamos y entregamos con puntualidad más de 40.000 kilos de textil.`,
    },
    {
      title: "Xeraco",
      img: `${baseUrl}img/espacios/xeraco.jpg`,
      descr: `El complejo de procesos inaugurado en 2017 cuenta con la última tecnología en cada fase de lavado y limpieza. Ubicado muy cerca de Gandía, añade 4.000 metros cuadrados más para aumentar la capacidad de servicio con todas las garantías higiénicas y procesos respetuosos con el medioambiente. Desde aquí se atiende toda la zona norte de la Comunidad Valenciana con la fiabilidad y la exigencia que requiere cada cliente. Ha sido reconocido como planta modelo en el sector sanitario.`,
    },
    {
      title: "Plantas Auxiliares",
      img: `${baseUrl}img/espacios/aux.jpg`,
      descr: `Contamos además con dos centros para tratamientos especializados en Benidorm. Estas plantas se reservan para trabajos delicados de lavandería select que precisan de atención manual específica, así como para la línea de negocio enfocada en el servicio para el hogar. En conjunto, 500 metros cuadrados con personal cualificado para desempeñar estas tareas.`,
    },
  ],
  expandables: [
    {
      title: "Igualdad de oportunidades y conciliación",
      descr: `Desde la Dirección General nos responsabilizamos de garantizar la no discriminación a todos los miembros del equipo. Para fomentar la convivencia de más de diez nacionalidades y concienciar a toda la plantilla, hemos elaborado un programa de sensibilización que se recoge en el Plan de Igualdad y una comisión específica que vela de forma activa por su cumplimiento.\n
Conscientes de la importancia de la conciliación, en Grupo LON ofrecemos programas de flexibilización en los puestos que no requieren presencia activa en planta.\n
También trabajamos en un  programa de inserción laboral para el colectivo de diversidad funcional y nuestras instalaciones son cien por cien accesibles.`,
      img: `${baseUrl}img/condiciones.jpg`,
    },
    {
      title: "Empleo de calidad",
      descr: `Trabajamos para ser la empresa referente del sector para trabajar fidelizando el talento, pero también nos proyectamos para acoger prácticas profesionales y tareas de voluntariado. Cada planta de procesado cuenta con su propio Comité de Empresa que vela por el mantenimiento de un buen clima laboral y representa a la plantilla en la toma de decisiones que la competen.\n
Nos preocupamos de crear ofertas de trabajo estable y de calidad y cuidamos los procesos de selección valorando las aptitudes y actitudes que requiere el puesto vacante. Nos implicamos para generar experiencias positivas, asegurar el respeto al candidato, garantizar la confidencialidad y la igualdad de oportunidades.`,
      img: `${baseUrl}img/calidad.jpg`,
    },
    {
      title: "Formación continua",
      float: true,
      img: `${baseUrl}img/ic_lon_university.png`,
      descr: `
          Nuestro compromiso con el desarrollo profesional de los empleados se plasma en LON University. Una modalidad de aprendizaje que proporciona a los trabajadores formación y capacitación relacionada con las diferentes áreas de la empresa y los conocimientos técnicos sobre los procesos y materiales. Se complementa con píldoras de aprendizaje distribuidas a través de los medios sociales en los que estamos presentes.\n
El desarrollo de una política de promoción interna es otra de las herramientas para favorecer la carrera profesional de todo el equipo, que goza de prioridad frente a posibles aspirantes externos.`,
    },
    {
      title: "Cuidado del medio ambiente",
      descr: `Estamos implicados al cien por cien en integrar la sostenibilidad en la cultura del Grupo LON. La gestión de los residuos es una responsabilidad común de toda empresa para disminuir el impacto ambiental buscando alternativas que eviten el residuo o lo reduzcan al máximo. Cuando no es posible, externalizamos su tratamiento a través de gestores autorizados.\n
Invertimos en tecnología de última generación que nos permite lavar un 10% más de ropa, consumiendo la misma cantidad de energía, agua y tiempo. Gracias al sistema de recirculación podemos recuperar parte del agua y de los detergentes reduciendo el consumo de estos en un 20%. Además, escogemos soluciones innovadoras con la menor carga química posible que garantice la durabilidad del textil.`,
    },
    {
      title: "Trabaja con nosotros",
      descr: `Somos una empresa familiar dedicada a la lavandería industrial en la que las personas son nuestro principal activo. Apostamos por el desarrollo profesional basado en el talento, creemos en la conciliación y la innovación es parte de nuestro ADN.\n
Si quieres trabajar en un grupo multicultural formado por personas con diferentes perfiles y experiencias, el Grupo LON es tu sitio.`,
    },
  ],
};

export const numbers = [
  {
    title: 156000,
    subtitle: "artículos cada día",
    description:
      "Procesamos más de 156.000 artículos cada día: servilletas, toallas, sábanas, ropa para clientes de hospital, ropa de personal sanitario...",
  },
  {
    title: 1500000,
    subtitle: "de Km al año",
    description:
      "Nuestra flota de transporte de 25 vehículos realiza más de un millón y medio de kilómetros para repartir y recoger mercancía, lo que supone dar ¡35 vueltas completas a la tierra en un año!",
  },
  {
    title: 220,
    subtitle: "trabajadores",
    description:
      "Trabajamos 220 personas, 116 mujeres y 104 hombres. Convivimos más de 10 nacionalidades y contamos con un programa de sensibilización que vela por la igualdad de los empleados.",
  },
];

export const items_vestuario_laboral = {
  Sanitario: {
    id: 1,
    title: "Sanitario",
    descr: `El mantenimiento en perfectas condiciones de desinfección e higiene de la uniformidad en los hospitales es de vital importancia. Aplicamos un exhaustivo proceso de lavado a altas temperaturas y con productos químicos que no dañan el medioambiente para garantizar la salud y bienestar del personal sanitario. Además, hemos incorporado tecnología de vanguardia y la más novedosa maquinaria para responder a la exigente normativa técnica de prendas sanitarias para hospitalización, quirófanos o enfermería, así como para la limpieza específica de los equipos de protección individual (EPI).`,
    img: `${baseUrl}img/vestuario/sanitario.jpg`,
    dots: [
      "Personalizamos las prendas y garantizamos la máxima calidad en la limpieza e higienización de los uniformes.",
      "Disponemos de una flota de vehículos propia para la recogida y reparto del vestuario laboral para controlar todo el proceso.",
      "Devolvemos siempre la ropa de trabajo en perfecto estado de uso, incluso cuando precisa algún arreglo como reparar cremalleras, botones o descosidos.",
    ],
  },
};

export const items_renting_textil = {
  Hotelero: {
    title: "Hotelero",
    descr: `Ayudamos a mejorar la experiencia en los hoteles proporcionando una estancia confortable a los huéspedes. El minucioso conocimiento del sector nos permite ofrecer un servicio de renting textil ajustado en precio y necesidades según el perfil del establecimiento. Disponemos de una amplia gama de ropa de cama y baño de diferentes calidades y características que puede personalizarse. Nuestro personal especializado asesora al cliente tras estudiar sus necesidades concretas para poner a su disposición el tipo de prendas que las cubra. Además, si lo prefiere, también puede adquirir el material.
    `,
    img: `${baseUrl}img/renting/hotelero.jpg`,
    dots: [
      "Ahorro de espacio y de costes, solo se paga por lo que se usa.",
      "Sustitución de ropa deteriorada y del faltante tras los recuentos mensuales.",
      "Garantizamos la disponibilidad de un stock de cinco juegos para atender cualquier imprevisto que pueda surgir.",
    ],
  },
  Hostelería: {
    title: "Hostelería",
    descr: `Limpieza y tacto agradable en mantelerías y servilletas son el primer impacto en el comensal para predisponerlo a disfrutar de la buena mesa. Nuestra propuesta de alquiler textil contempla la gestión integral de la lencería a la medida de cada cliente. Incluye todos los procesos, desde la recogida, transporte, lavado, planchado, higienización hasta el empaquetado, clasificación y entrega a la carta en el restaurante.`,
    img: `${baseUrl}img/renting/hosteleria.jpg`,
    dots: [
      "Atención personalizada y asesoramiento para la elección del ajuar más indicado para el tipo de establecimiento.",
      "Integramos el servicio de lavandería y limpieza con el alquiler del textil en una sola empresa.",
      "Disponemos de capacidad para atender y resolver imprevistos en un tiempo récord.",
    ],
  },
  Sanitario: {
    title: "Sanitario",
    descr: `Nuestro servicio de renting textil proporciona a los centros hospitalarios mejoras operativas claras al poder ajustar la disposición de lencería a las necesidades reales. Aseguramos el mantenimiento del stock y el arreglo y reposición de las prendas estropeadas por la limpieza y el uso. Supone un ahorro económico y de tiempo al cubrir el recambio continuo de lencería y uniformes y aglutinar en una sola empresa todas las gestiones.`,
    img: `${baseUrl}img/renting/sanitario.jpg`,
    dots: [
      "Evita la inversión inicial en la compra de artículos de textil.",
      "Permite el control del gasto puesto que el pago por el abastecimiento y limpieza de la lencería se realiza cada mes según la cuota fijada por contrato.",
      "Mejora las condiciones higiénicas en un sector donde la limpieza, higiene y desinfección es prioritaria.",
    ],
  },
};

export const items_industrial = {
  Hostelería: {
    title: "Hostelería",
    descr: `Lavamos el textil de cuatro clientes con estrella Michelin y de los principales restaurantes de la Costa Blanca para los que la reputación del establecimiento y los estándares de higiene constituyen la base del servicio a sus clientes. La calidad y limpieza del textil que exhiben en los servicios en mesa, tras el paso por nuestros tratamientos de lavandería industrial, se ha convertido en un plus que aprecia cada comensal. La atención a los detalles que se presta en el Grupo LON les ofrece un punto de distinción.`,
    img: `${baseUrl}img/industrial/hosteleria.jpg`,
    dots: [
      "Atención personalizada y respuesta inmediata a las necesidades en temporada alta.",
      "Ayudamos al restaurante a proporcionar una experiencia de excelencia al cliente que le fidelizará.",
      "Uso de maquinaria ecológica y detergentes sostenibles para prolongar la vida útil de mantelerías y servilletas.",
    ],
  },
  Hotelero: {
    title: "Hotelero",
    descr: `Las principales cadenas hoteleras, apartamentos y viviendas
      vacacionales confían a Grupo LON su servicio de lavandería
      industrial. Disponemos de capacidad para tratar más de 156.000
      artículos cada día y dar servicio en toda la zona de Levante y
      Murcia donde se concentra una gran parte de la actividad
      turística en España. Nuestras plantas de tratamiento están
      dotadas de tecnología de última generación para asegurar la
      máxima calidad y la protección del medioambiente. Recogemos y
      entregamos en las instalaciones de nuestros clientes con
      puntualidad.
    `,
    img: `${baseUrl}img/servicios_beneficios.jpeg`,
    dots: [
      "Garantizamos la calidad óptima en el acabado y la desinfección absoluta de las prendas.",
      "Adaptamos cada proceso a las necesidades de la prenda y el tipo de manchas. ",
      "Utilizamos maquinaria y productos innovadores que nos permiten realizar tratamientos personalizados.",
    ],
  },
  Sanitario: {
    id: 1,
    title: "Sanitario",
    descr: `Lavamos la ropa de 20 hospitales, de los que 17 están integrados en el sistema público de salud de la Comunidad Valenciana. Proporcionamos a diario ropa limpia y confortable perfectamente aislada y desinfectada para los sanitarios y los pacientes. Sabemos que garantizar la higiene en estos centros es una cuestión vital, por lo que contamos con lavadoras industriales de barrera sanitaria para evitar la contaminación cruzada y conseguir el máximo nivel de asepsia con la mayor eficiencia en el gasto energético. Además, pasamos controles bacteriológicos periódicos realizados por laboratorios independientes.`,
    img: `${baseUrl}img/industrial/sanitario.jpg`,
    dots: [
      "Cumplimos de forma rigurosa los tiempos y niveles de servicio.",
      "Contamos con personal cualificado en el tratamiento del textil hospitalario.",
      "Sistema de trazabilidad informatizado para conocer en todo momento los procesos aplicados a las prendas.",
    ],
  },
};

export const items_select = {
  Hostelería: {
    id: 1,
    title: "Hostelería",
    descr: `Un servicio a precios competitivos para restaurantes que quieren ofrecer un toque extra de calidad al vestir sus mesas. Cuidamos el detalle y la imagen de cada cliente personalizando al máximo este servicio propio de tintorerías para que puedan conservar sus señas de identidad. Contamos con personal cualificado en el trabajo manual para que las mantelerías, servilletas y uniformes cumplan con los estándares más altos de calidad. Seriedad en las entregas.`,
    img: `${baseUrl}img/select/hosteleria.jpg`,
    dots: [
      "Nos adaptamos a las necesidades de calidad y plegado de cada cliente.",
      "Trabajamos con productos de lavado y limpieza que preservan del desgaste a los tejidos.",
      "Trato personalizado para atender hasta el más mínimo detalle y lograr la satisfacción del cliente.",
    ],
  },
  "Casas Rurales": {
    id: 1,
    title: "Casas Rurales",
    descr: `La ropa de cama y las toallas son dos elementos esenciales para que los clientes quieran repetir estancia. El servicio de lavandería select del Grupo LON está pensado para los establecimientos que priman el confort y la comodidad durante la estadía de sus huéspedes. Cuidamos al máximo todos los detalles, incluido el arreglo de desperfectos o descosidos en el textil.`,
    img: `${baseUrl}img/select/casas_rurales.jpg`,
    dots: [
      "Hacemos nuestra la singularidad de cada cliente.",
      "Nos comprometemos cumplir con rigor los plazos de recogida y entrega.",
      "Personal especializado cuida todos los detalles del ajuar de los establecimientos.",
    ],
  },
  Apartamentos: {
    id: 1,
    title: "Apartamentos",
    descr:
      "Un servicio a medida de limpieza y planchado de ropa de cama, toallas, fundas, nórdicos y el textil necesario para que las personas que se hospedan se sientan como en casa durante su estancia. Ofrecemos alta calidad a precios muy competitivos ajustados a las necesidades específicas de cada cliente. Todo, para que los visitantes disfruten de una experiencia única y repitan.",
    img: `${baseUrl}img/select/apartamentos.jpg`,
    dots: [
      "Nos encargamos de mantener la ropa de cama y baño en perfecto estado de uso.",
      "Estamos especializados en la cobertura de las necesidades de alojamientos turísticos.",
      "Nuestro sistema exclusivo de trazabilidad evita la pérdida de las prendas.",
    ],
  },
  Doméstico: {
    id: 1,
    title: "Doméstico",
    descr:
      "Contamos con un servicio específico para hogares que incluye el tratamiento y limpieza de piezas textiles de gran volumen como alfombras, cortinas y stores, edredones y mantas. Se completa con  las coladas y planchado exprés, la limpieza en seco y los procesos especiales para trajes y vestidos de fiesta. Del mismo modo, los particulares pueden solicitar el servicio de arreglos y costura y la entrega en domicilio.",
    img: `${baseUrl}img/select/domestico.jpg`,
    dots: [
      "Acceso para particulares de los altos estándares de calidad del Grupo LON.",
      "Tratamiento específico y adecuado para prendas y tejidos delicados que garantiza su duración.",
      "Acabado manual por parte de personal especializado para devolver la ropa en perfecto estado.",
    ],
  },
  Spas: {
    id: 1,
    title: "Spas",
    descr:
      "La demanda de calidad y la atención por el detalle que precisa el sector de belleza, salud y bienestar nos ha animado a incorporar sus establecimientos a nuestro servicio de lavandería select. El lavado y secado de toallas de diferentes tipos y tamaños en nuestras instalaciones además de aportar la máxima higiene contribuye a transmitir una imagen de limpieza y seguridad imprescindible en estos negocios.",
    img: `${baseUrl}img/select/spas.jpg`,
    dots: [
      "Utilizamos secadoras específicas para eliminar el exceso de humedad sin que las prendas pierdan suavidad.",
      "Los productos químicos de limpieza cumplen todas las normas de seguridad y respeto por el medioambiente.",
      "Te ayudamos a consolidar tu reputación como profesional de primer nivel.",
    ],
  },
};

export const servicios = [
  {
    title: "Lavandería Industrial",
    slug: "lavanderia-industrial",
    header: {
      image: `${baseUrl}img/headers/industrial.jpg`,
      icon: `${baseUrl}img/ic_industrial_white.png`,
    },
    descr2:
      "Somos especialistas en lavado, planchado y desinfección del textil para uso diario en hoteles, restaurantes y hospitales. Destacamos por la respuesta inmediata y la cercanía.",
    link: "#",
    items: items_industrial,
    expandables: [
      {
        title: "Trazabilidad",
        descr: `Las tres décadas de experiencia en lavandería industrial nos permiten entender y satisfacer las necesidades de cada sector para el que trabajamos. Por eso, ningún cliente del Grupo LON temen por la pérdida de sus prendas o que se mezclen con las de otros establecimientos. Lo conseguimos aplicando un sistema exclusivo de trazabilidad completamente informatizado que permite identificar y localizar la ropa de cada cliente en todo momento.<br>
  Antes de que la ropa sucia salga del domicilio del cliente, documentamos la matrícula de los carros en los que viajará hasta nuestras instalaciones, el peso y sus características especiales. Al llegar a la planta se realiza este mismo proceso para asegurarnos que durante el transporte no se haya mezclado. Para aplicar los diferentes procesos de lavado y limpieza es imprescindible introducir un código de activación. Y cuando está planchada y empaquetada, se identifica con una etiqueta  donde figura el cliente, los procesos y el operario que la ha manipulado, antes de trasladarla a la casa del cliente.`,
      },
      {
        title: "Tecnología",
        descr: `Invertir en tecnología puntera para mejorar la calidad, fiabilidad y coste forma parte el ADN del Grupo LON. Del mismo modo, apostamos por la I+D+i para lograr las mayores garantías higiénicas y los procesos medioambientalmente más respetuosos. Hemos incorporado un considerable número de equipos de primeras marcas en las plantas industriales para perfeccionar más los procesos propios de la lavandería industrial. Todo, para cumplir nuestros compromisos con los clientes al dotarnos de la capacidad suficiente en cualquiera de nuestras plantas. Incluso, podemos dar el apoyo necesario para solventar cualquier incidencia que impidiera operar con normalidad en el menor plazo posible.<br>
      Entre los nuevos equipos destacan:<br>
    • Sistema informatizado de clasificación de ropa con almacén de carga aérea y alimentación automática a túneles de lavado<br>
    • Un túnel y prensa de lavado<br>
    • Cuatro nuevas secadoras con dos nuevos alimentadores automáticos para mantener la integridad de la barrera sanitaria<br>
    • Cámaras de ozono para la desinfección de carros de transporte y los elementos que transitan desde la zona de ropa sucia a la de ropa limpia<br>
    • Un tren de planchado, plegado y apilado<br>
    • Una línea completa de emperchado, secado, planchado y plegado de uniformes`,
      },
      {
        title: "Medioambiente",
        descr: `Cuidar del planeta es uno de los valores que nos guían. Cada día lo ponemos en práctica reciclando los residuos y reduciendo el consumo de agua y el impacto de los detergentes. Como primera medida, buscamos alternativas para evitar la generación del residuo en origen y cuando la prevención, el reciclaje o la reutilización no es posible en el propio centro de trabajo, externalizamos su tratamiento a través de gestores autorizados.<br>
  Entre las mejoras introducidas en el área de lavandería destacan la sustitución del formato papel por un sistema de trazabilidad electrónico, el uso de sacas de tela en clientes pequeños para el transporte de ropa reduciendo el consumo de bolsas de plástico o la sustitución del empaquetado manual por otro automático más eficiente.<br>
  Respecto a la maquinaria, el nuevo túnel de lavado procesa un 10% más de ropa, consumiendo la misma cantidad de energía, agua y tiempo y permite reducir el uso de lavadoras, lo que supone un ahorro en el consumo de agua de hasta el 67% por kilo de ropa lavada. Su sistema de recirculación de agua ayuda a recuperar parte del agua y de los detergentes reduciendo el consumo de estos últimos en un 20%. Además, los sistemas de dosificación garantizan el cumplimiento de las normas relativas a la seguridad en el trabajo y protección del medioambiente.`,
      },
    ],
    imageWithText: {
      title: "Beneficios del Servicio",
      text: `<b>· Reducción de costes.</b> El coste del servicio externalizado de lavandería industrial es un 40% más barato que mantener un departamento propio.<br><br>
<b>· Mejora de la calidad.</b> La ropa queda más limpia, más blanca, más esponjosa cuando se procesa en  nuestra lavandería industrial debido a la maquinaria de última generación y al estudio específico de los productos químicos de limpieza adecuados para cada programa de lavado.<br><br>
<b>· Facilita la profesionalización de los clientes.</b> Pueden concentrar todo su esfuerzo en proporcionar   la máxima calidad en los servicios propios de hotelería, restauración y hospitales.<br><br>
<b>· Contribución a la mejora medioambiental.</b> En Grupo LON cumplimos con todos los exigentes requisitos legales. Además, la nueva maquinaria consume menos agua, electricidad, tiempo y detergente, frente a maquinaria más antigua, cuyo mantenimiento es más costoso y el consumo por cada proceso de lavado mucho más elevado.`,
      img: `${baseUrl}img/industrial/servicios.jpg`,
    },
  },
  {
    title: "Lavandería Select",
    slug: "lavanderia-select",
    header: {
      image: `${baseUrl}img/headers/select.jpg`,
      icon: `${baseUrl}img/ic_select_white.png`,
    },
    link: "#",
    items: items_select,
    expandables: [
      {
        title: "¿Cómo lo hacemos?",
        descr: [
          "La ropa llega a la planta en uno de nuestros vehículos. Se lleva a la zona de lavado y se pesa cada carro de ropa sucia para registrar los datos en un programa informático exclusivo del Grupo LON. Así conocemos la trazabilidad de cada prenda, los kilos de ropa sucia que han llegado y el tipo de ropa que es.",
          "Preselección para el lavado. Separamos el blanco del color y cada uno de estos lo volvemos a separar en tres grupos: rizo (toallas y mantas), plano (sábanas, fundas, mantelerías, es decir, todo lo que se va a planchar) y ropa de forma (uniformidad, pijamas de pacientes).",
          "Lavado. Puede realizarse en túnel de lavado o en lavadoras. Las máquinas no funcionan hasta que se introduce el código de cliente al que pertenece la ropa que se va a lavar y el tipo de ropa que es. Para cada cliente ya están preprogramados los ciclos a utilizar, la cantidad de agua, la temperatura, el tipo de detergente y el suavizante.",
          "La ropa se redirige a la zona de limpio de su área correspondiente. Una vez seca, el proceso de acabado se realiza de manera manual.",
          "Planchado y plegado manual de la ropa, según las indicaciones del cliente. Permite un control de calidad final muchísimo más exhaustivo y detectar prendas que precisan un repaso con plancha de mano, estirar pequeñas arrugas o cualquier otro retoque.",
          "Empaquetado, plastificado y etiquetado con el nombre del cliente, tipo de ropa, cantidad de unidades y operario que ha procesado el paquete.`",
        ],
      },
    ],
    imageWithText: {
      title: "Beneficios del Servicio",
      text: `La lavandería select ofrece atención personalizada y tratamiento específico para cada producto.<br><br>
      • Control continuo de personal especializado en cada proceso que se aplica al textil para que el resultado sea plenamente satisfactorio.<br><br>
      • Una oportunidad única para que los establecimientos de restauración, turísticos, belleza y hogar se beneficien de los altos estándares de calidad de nuestra lavandería industrial pero aplicados de forma manual.<br><br>
      • Cuidamos de la ropa tal como lo harías tú con cantidades reducidas, procesos adecuados y productos de primera calidad.`,
      img: `${baseUrl}img/select/servicios.jpg`,
    },
  },
  {
    title: "Renting textil",
    slug: "renting-textil",
    header: {
      image: `${baseUrl}img/headers/renting.jpg`,
      icon: `${baseUrl}img/ic_renting_white.png`,
    },
    link: "#",
    items: items_renting_textil,
    expandables: [
      {
        title: "Servicio",
        descr: `Nuestro servicio de alquiler evita al cliente el desembolso de la dotación textil que requiere su negocio, a lo que se suma el ahorro de espacio de almacenaje. Grupo LON pone a su disposición cinco juegos de todo el textil personalizados con la imagen del establecimiento y repone de inmediato la ropa que se pierde o se estropea por el uso. Además, el cliente solo paga por las prendas que envía a lavar, lo que representa otro ahorro de costes importante.`,
      },
      {
        title: "Calidades",
        descr: `El renting textil está modificando el formato de suministro de ropa en el ámbito hospitalario, hotelería y restauración. Con el fin de adaptarnos a los diferentes perfiles de clientes que conforman estos mercados, disponemos de una amplia gama de productos entre los que elegir. Según las características del negocio, el cliente puede optar por textil de tipo estándar, profesional y luxury.`,
      },
    ],
    imageWithText: {
      title: "Beneficios del servicio",
      text: `
    • Servicio de entrega y recogida gratuito para disponer en todo momento de ropa impecable y desinfectada.<br><br>
    • Atención personalizada e inmediata por parte de un responsable de Grupo LON para resolver cualquier cuestión relacionada con la gestión de lencería.<br><br>
    • Garantizamos el mantenimiento del stock acordado en el contrato y la reposición cada año del textil que se haya deteriorado tras realización de inventario junto con el cliente.<br><br>`,
      img: `${baseUrl}img/renting/beneficios.jpg`,
    },
  },
  {
    title: "Vestuario Laboral",
    slug: "vestuario-laboral",
    header: {
      image: `${baseUrl}img/headers/vestuario.jpg`,
      icon: `${baseUrl}img/ic_vestuario_white.png`,
    },
    link: "#",
    items: items_vestuario_laboral,
    expandables: [
      {
        title: "Uniformidad",
        descr: `Nuestra lavandería industrial, dotada de maquinaria de última generación en lavadoras de barrera, nos permite el tratamiento de ropa de trabajo y equipamiento específico con total seguridad.  Podemos descontaminar e incluso suprimir por completo las partículas en los equipos de trabajo.  La atención personalizada y el expertise en el procesado de prendas reflectantes, ignífugas, de rodio o con normativas de protección específicas nos distingue en el mercado.`,
      },
      {
        title: "Servicio",
        descr: `La buena imagen de las empresas comienza por gestos como cuidar los uniformes de sus empleados. En Grupo LON disponemos de soluciones específicas a precios accesibles para cada sector de actividad. Podemos aplicar distintos tratamientos de limpieza como el procesado en salas blancas, lavado en seco o esterilización de prendas para que el vestuario laboral cumpla con todas las medidas de seguridad e higiene.`,
      },
    ],
    imageWithText: {
      title: "Beneficios del Servicio",
      text: `• Proporcionamos ropa de trabajo cómoda, personalizada y segura sin que suponga un desembolso para el cliente.<br><br>
        • Dejar en manos expertas una tarea tan delicada como la desinfección y limpieza de la ropa de trabajo permite concentrar esfuerzos en otras áreas de negocio esenciales.<br><br>
        • Ayudamos a mantener un nivel de excelencia en la imagen corporativa de las empresas y nos comprometemos a proporcionar un servicio impecable y puntual cada día.<br><br>
        • Aseguramos un acabado de calidad al mejor precio y con el nivel máximo de higiene que solo puede proporcionar una lavandería industrial.`,
      img: `${baseUrl}img/vestuario/servicios.jpg`,
    },
  },
];

export const slider = [
  {
    title: "Lavandería Select",
    icon: `${baseUrl}img/ic_lavanderia_select.png`,
    image: `${baseUrl}img/headers/select.jpg`,
    descr:
      "Cuidamos al detalle las necesidades específicas del textil para restaurantes, centros de estética y spas y manipulamos con precisión el producto para que luzca una presentación perfecta. Para clientes que requieren un toque de calidad y distinción.",
    to: "/servicios/lavanderia-select",
    slug: "lavanderia-select",
    descr2:
      "Una atención impecable y minuciosa para sectores que demandan un servicio top de limpieza textil, como restaurantes, casas rurales o apartamentos.",
  },
  {
    title: "Lavandería industrial de confianza",
    titleShort: "Lavandería industrial",
    icon: `${baseUrl}img/ic_lavanderia_industrial.png`,
    image: `${baseUrl}img/slider/industrial.jpg`,
    descr:
      "Servicio integral para hoteles, hostelería, hospitales, clínicas y residencias adaptado a las singularidades y exigencias normativas de cada sector.",
    to: "/servicios/lavanderia-industrial",
    slug: "lavanderia-industrial",
  },
  {
    title: "Renting textil",
    icon: `${baseUrl}img/ic_renting_textil.png`,
    image: `${baseUrl}img/slider/renting.jpg`,
    descr:
      "Alquiler y gestión de prendas seleccionadas personalizadas para cada cliente con rigor y siempre impecables pensando en la rentabilidad para el cliente y evitando la rotura de stock.",
    descr2:
      "Un servicio que supone un importante ahorro en los procesos de selección de material y garantiza el mantenimiento del stock en hostelería, la restauración y en el sector sanitario.",
    to: "/servicios/renting-textil",
    slug: "renting-textil",
  },
  {
    title: "Vestuario laboral a la carta",
    titleShort: "Vestuario laboral",
    icon: `${baseUrl}img/ic_vestuario_laboral.png`,
    image: `${baseUrl}img/slider/vestuario.jpg`,
    descr:
      "Lavado del uniforme laboral con procesos propios para cada profesión garantizando su perfecto estado de uso e higienización así como el confort y buena presencia de los empleados.",
    descr2:
      "Ropa de trabajo personalizada y sujeta a las exigentes normas de seguridad y protección específicas para cada actividad. Tratamos las prendas para un uso posterior seguro y confiable.",
    to: "/servicios/vestuario-laboral",
    slug: "vestuario-laboral",
  },
];

export const videos = {
  inicio: {
    default: `${baseUrl}video/inicio.mp4`,
    webm: `${baseUrl}video/inicio.webm`,
  }
}

export const docs = {
  cmss: `${baseUrl}cmss.ed.03.pdf`,
  compromiso: `${baseUrl}compromiso.pdf`,
}